import { Box, Grid, Text } from '@chakra-ui/react'
interface IMenuItem {
  name: string
  price: number
  priceAdjustment: number
  smPrice?: number
  lgPrice?: number
  description?: string
  type?: 'main' | 'sub' | 'extra'
}

const formatPrice = (num: number) => {
  // If the decimal part is 0, return the integer part
  return `$${num % 1 === 0 ? num.toFixed(0) : num.toString()}`
}

const MenuItem = ({
  name,
  description,
  price,
  smPrice,
  lgPrice,
  // priceAdjustment,
  type,
}: IMenuItem) => {
  // Get item font size
  let itemSize = 'md'
  if (type === 'main') itemSize = 'xl'
  if (type === 'sub' || type === 'extra') itemSize = 'sm'

  // Get price text to be displayed
  let priceText = '--'
  if (price) priceText = formatPrice(price)
  // if (priceAdjustment)
  //   priceText = `${priceAdjustment > 0 ? '+' : '-'}$${Math.abs(
  //     priceAdjustment
  //   )}`

  return (
    <Box width="100%">
      <Grid
        textStyle="h3"
        fontSize={itemSize}
        fontWeight="normal"
        gridAutoFlow="column"
        gridTemplateColumns="1fr 5rem"
        gridColumnGap={2}
        width="100%"
      >
        <Text fontSize="1em">{name}</Text>
        {smPrice && lgPrice ? (
          <Text fontSize="md">
            {formatPrice(smPrice)} / {formatPrice(lgPrice)}
          </Text>
        ) : (
          <Text fontSize="1em">{priceText}</Text>
        )}
      </Grid>
      {description && (
        <Text
          fontSize="xs"
          fontWeight="light"
          width="80%"
          maxWidth="30rem"
          mt={0.6}
          mb={2}
        >
          {description}
        </Text>
      )}
    </Box>
  )
}

export default MenuItem
