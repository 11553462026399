const MENU_ITEMS = {
  BURGERS: [
    {
      name: 'SINGLE',
      description: `IN-HOUSE, FRESH GROUND BEEF, YELLOW AMERICAN CHEESE, ONIONS, SCRATCH PICKLES, YELLOW MUSTARD, IN BETWEEN MARTIN'S POTATO ROLL`,
      price: 8.5,
      type: 'main',
    },
    {
      name: 'DOUBLE',
      description: `IN-HOUSE, FRESH GROUND BEEF x2, YELLOW AMERICAN CHEESE x2, ONIONS, SCRATCH PICKLES, YELLOW MUSTARD, IN BETWEEN MARTIN'S POTATO ROLL`,
      price: 11,
      type: 'main',
    },
    {
      name: 'ADD HOUSE CHILI',
      price: 3,
      type: 'extra',
    },
    {
      name: 'ADD PASTRAMI',
      price: 5.5,
      type: 'extra',
    },
    {
      name: 'SUB IMPOSSIBLE PATTY',
      price: 4,
      type: 'sub',
    },
    {
      name: 'SUB GLUTEN-FREE BUN',
      price: 3,
      type: 'sub',
    },
  ],
  'HOT DOGS': [
    {
      name: 'REGULAR',
      description:
        '9 IN. VIENNA BEEF NATURAL CASING, ONION SAUCE, MUSTARD, SCRATCH RELISH',
      price: 7,
      type: 'main',
    },
    {
      name: 'CHILI DOG',
      description:
        '9 IN. VIENNA BEEF NATURAL CASING, HOUSE CHILI, MUSTARD, ONIONS',
      price: 8,
      type: 'main',
    },
    {
      name: 'CHILI CHEESE DOG',
      price: 9,
      description:
        '9 IN. VIENNA BEEF NATURAL CASING, HOUSE CHILI, AMERICAN CHEESE, MUSTARD, ONIONS',
      type: 'main',
    },
  ],
  'FRIES & OTHERS': [
    {
      name: 'CRINKLE CUT FRIES',
      description: 'POTATO FRIES COOKED TO GOLDEN CRISP',
      price: 5,
      type: 'main',
    },
    {
      name: 'CHILI CHEESE FRIES',
      description:
        'POTATO FRIES, HOUSE CHILI, AMERICAN CHEESE, ONIONS, HOUSE PICKLES',
      price: 0,
      smPrice: 8,
      lgPrice: 14,
      type: 'main',
    },
    {
      name: 'PASTRAMI FRIES',
      description:
        'PASTRAMI, SHREDDED CHEESE, SOUR CREAM, RAW ONION, SCRATCH PICKLES',
      price: 0,
      smPrice: 10,
      lgPrice: 16,
      type: 'main',
    },
    {
      name: 'PASTRAMI CHILI CHEESE FRIES',
      description:
        'PASTRAMI, HOUSE CHILI, SHREDDED CHEESE, SOUR CREAM, RAW ONION, SCRATCH PICKLES',
      price: 0,
      smPrice: 13,
      lgPrice: 19,
      type: 'main',
    },
    {
      name: 'PATTY MELT',
      description: `8 OZ HOUSE BLEND PATTY, SWISS CHEESE, AMERICAN CHEESE, GRILLED ONIONS, RYE BREAD`,
      price: 16,
      type: 'main',
    },
    {
      name: 'GRILLED CHEESE',
      description: `YELLOW AMERICAN CHEESE x2, ONIONS, SCRATCH PICKLES, YELLOW MUSTARD, IN BETWEEN MARTIN'S POTATO ROLL`,
      price: 5,
      type: 'main',
    },
    {
      name: 'LONGANISA BREAKFAST SANDWICH',
      description: `WEEKENDS ONLY TIL 4PM`,
      price: 10,
      type: 'main',
    },
    {
      name: 'RANCH',
      price: 1.5,
      type: 'extra',
    },
    {
      name: 'CUP OF PICKLES',
      price: 2,
      type: 'extra',
    },
    {
      name: 'CUP OF CHILI',
      price: 3,
      type: 'extra',
    },
  ],
  SHAKES: [
    {
      name: 'CLASSIC SHAKE',
      description: 'CHOCOLATE | STRAWBERRY | VANILLA | COOKIES & CREAM',
      price: 7,
    },
    {
      name: 'UBE COCONUT SHAKE',
      price: 10,
    },
  ],
  DRINKS: [
    {
      name: 'MEXICAN SODA',
      price: 3.5,
    },
    {
      name: 'CANNED SODA',
      price: 2.5,
    },
    {
      name: 'SPARKLING WATER',
      price: 3.5,
    },
    {
      name: 'SPRING WATER',
      price: 2.5,
    },
    {
      name: 'LEMONADE',
      price: 3.25,
    },
    {
      name: 'ICED TEA',
      price: 2.75,
    },
  ],
}

export default MENU_ITEMS
